/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
const Rails = require('rails-ujs');
const $ = require('jquery');
const jQuery = $;
require('eonasdan-bootstrap-datetimepicker');
const moment = require('moment');
require('moment/locale/ja');
const qq = require('fine-uploader');
require('select2');
//window.$ = $ // TEST ONLY
require('bootstrap/dist/js/bootstrap');

$(() => {
  Rails.start();
  $('.datepicker').datetimepicker({
    format: 'YYYY/MM/DD',
    dayViewHeaderFormat: 'YYYY年 MMMM',
    locale: moment.locale('ja'),
    icons: {
      previous: 'fa fa-chevron-left',
      next: 'fa fa-chevron-right'
    }
  });

  $(document).on('click', 'a[data-form]', function(event) {
    event.preventDefault();

    const form = $(this).closest('form');
    const opt = $(this).data('form');
    const singleton = $(this).attr('href') === '#';
    const checked = form.find(`input[data-${opt.target}]:checked`);

    if(checked.length == 0){
      alert('処理対象を選択してください');
      return;
    }

    if(!opt.multiple && checked.length > 1){
      alert('処理対象は単一で選択してください');
      return;
    }

    const url = singleton ? checked.data(opt.target) : $(this).attr('href');
    if (!url) throw 'undefined url';
    const method = opt.method.toLowerCase();
    if (method === 'get') {
      location.href = url;
    } else {
      if (method !== 'post') {
        $('<input/>', {
          type: 'hidden',
          name: '_method',
          value: method
        }).appendTo(form);
      }
      form.attr('action', url);
      Rails.refreshCSRFTokens();
      form.submit();
    }
  });

  $('.ac select').select2({
    language: {
      noResults: () => '項目がありません'
    },
    tags: true,
    width: '75%',
    allowClear: true,
    placeholder: ""
  });

  function updateFileList(ele, fileId, url){
    var fileLink = qq(ele).getByClass('file-link')[0];
    $(fileLink).attr("href", url);
    var hidden = qq(ele).getByClass('file-resource-id')[0];
    $(hidden).val(fileId);
  }

  if($('#file-uploader').length){
    const fileUploader = $('#file-uploader');
    const url = '/file_resources';
    const fileType = fileUploader.data('file-type') || '';
    new qq.FineUploader({
      element: fileUploader[0],
      template: 'file-uploader-template',
      request: {
        endpoint: url,
        filenameParam: 'file_resource[name]',
        inputName: 'file_resource[avatar]',
        params: { 'file_resource[file_type]' : fileType }
      },
      failedUploadTextDisplay: {
        mode: 'custom'
      },
      session: {
        endpoint: url,
        params: {
          order_id: fileUploader.data('order-id') || '',
          file_type: fileType
        }
      },
      deleteFile: {
        enabled: true,
        endpoint: url,
        deletingFailedText: '削除に失敗しました',
        deletingStatusText: '削除中...',
      },
      validation:{
        itemLimit: fileUploader.data('item-limit') || 0,
      },
      messages: {
        tooManyItemsError: '一度に登録可能な数を超えています',
      },
      text: {
        failUpload: '登録に失敗しました',
        waitingForResponse: '処理中...',
      }
      ,
      callbacks: {
        onComplete: function(jsId, name, response){
          if(response.success){
            this.setUuid(jsId, response.uuid);
            updateFileList(this.getItemByFileId(jsId), response.uuid, response.url);
          }
        },
        onSessionRequestComplete: function(responses, success){
          if(success){
            for(let i = 0; i < responses.length; ++i){
              updateFileList(this.getItemByFileId(i), responses[i].uuid, responses[i].url);
            }
          }
        }
      }
    });
  }
});
